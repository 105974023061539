import { Injector, NgModule } from '@angular/core';

import { CellBuilder } from 'core-app/features/work-packages/components/wp-fast-table/builders/cell-builder';
import { WorkPackageResource, } from 'core-app/features/hal/resources/work-package-resource';
import { editFieldContainerClass } from "core-app/shared/components/fields/display/display-field-renderer";


(CellBuilder as any).prototype.render = function(container: HTMLElement, workPackage: WorkPackageResource, attribute: string) {
  const schema = this.schemaCache.of(workPackage);
  const mappedName = schema.mappedName(attribute);
  const hasBaseline = attribute !== "id" && this.wpTableBaseline.isChanged(workPackage, mappedName);
  container.classList.add("wp-table--cell-container", editFieldContainerClass, attribute);

  const displayElement = this.fieldRenderer.render(workPackage, attribute, null);

  if (hasBaseline) {
    displayElement.classList.add("op-table-baseline--field", "op-table-baseline--new-field");
    this.prependChanges(container, workPackage, mappedName);
  }

  if (attribute.includes("custom")) {
    container.classList.add("wp-field-custom");
  }

  container.appendChild(displayElement);
}

@NgModule({})

export class PluginModule {
  constructor(injector: Injector) { }
}
